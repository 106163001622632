import { RouteConfig } from 'vue-router';
import Layout from '@/layout/index.vue';
const basePath = `/mall/apps/imall`;
const router: Array<RouteConfig> = [
  {
    path: basePath,
    name: '积分商城',
    component: Layout,
    children: [
      {
        path: `${basePath}/home`,
        name: `商城首页`,
      },
      {
        path: `${basePath}/pms`,
        name: '商品管理',
        children: [
          {
            path: `${basePath}/pms/product`,
            name: `商品列表`,
          },
          {
            path: `${basePath}/pms/addProduct`,
            name: `添加商品`,
          },
          {
            path: `${basePath}/pms/updateProduct`,
            name: `编辑商品`,
            meta: { hidden: true },
          },
          {
            path: `${basePath}/pms/productCate`,
            name: '商品分类',
          },
          {
            path: `${basePath}/pms/addProductCate`,
            name: '添加商品分类',
            meta: { hidden: true },
          },
          {
            path: `${basePath}/pms/updateProductCate`,
            name: '编辑商品分类',
            meta: { hidden: true },
          },
          {
            path: `${basePath}/pms/productType`,
            name: '商品类型',
          },
          {
            path: `${basePath}/pms/addProductType`,
            name: '添加商品类型',
            meta: { hidden: true },
          },
          {
            path: `${basePath}/pms/updateProductType`,
            name: '编辑商品类型',
            meta: { hidden: true },
          },
          {
            path: `${basePath}/pms/productAttr`,
            name: '参数管理',
          },
          {
            path: `${basePath}/pms/productAttrList`,
            name: '商品参数列表',
            meta: { hidden: true },
          },
          {
            path: `${basePath}/pms/addProductAttr`,
            name: '添加商品参数',
            meta: { hidden: true },
          },
          {
            path: `${basePath}/pms/updateProductAttr`,
            name: '修改商品参数',
            meta: { hidden: true },
          },
          {
            path: `${basePath}/pms/brand`,
            name: '品牌管理',
          },
          {
            path: `${basePath}/pms/addBrand`,
            name: '添加品牌',
            meta: { hidden: true },
          },
          {
            path: `${basePath}/pms/updateBrand`,
            name: '编辑品牌',
            meta: { hidden: true },
          },
          {
            path: `${basePath}/pms/comment`,
            name: '商品评论',
          },
          {
            path: `${basePath}/pms/commentAudit`,
            name: '删除评论审核',
          },
        ],
      },
      {
        path: `${basePath}/oms`,
        name: '订单管理',
        children: [
          {
            path: `${basePath}/oms/order`,
            name: `订单列表`,
          },
          {
            path: `${basePath}/oms/orderAudit`,
            name: `订单删除审核`,
          },
          {
            path: `${basePath}/oms/orderDetail`,
            name: `订单详情`,
            meta: { hidden: true },
          },
          {
            path: `${basePath}/oms/deliverOrderList`,
            name: `发货列表`,
            meta: { hidden: true },
          },
          {
            path: `${basePath}/oms/orderSetting`,
            name: `订单设置`,
          },
          {
            path: `${basePath}/oms/returnApply`,
            name: `退货申请处理`,
          },
          {
            path: `${basePath}/oms/refundApply`,
            name: `退款申请处理`,
          },
          {
            path: `${basePath}/oms/returnReason`,
            name: `退货原因设置`,
          },
          {
            path: `${basePath}/oms/returnApplyDetail`,
            name: `退货原因详情`,
            meta: { hidden: true },
          },
        ],
      },
      {
        path: `${basePath}/sms`,
        name: '营销管理',
        children: [
          {
            path: `${basePath}/sms/flash`,
            name: '秒杀活动列表',
          },
          {
            path: `${basePath}/sms/flashSession`,
            name: '秒杀时间段列表',
            meta: { hidden: true },
          },
          {
            path: `${basePath}/sms/selectSession/:id`,
            name: '秒杀时间段选择',
            meta: { hidden: true },
          },
          {
            path: `${basePath}/sms/flashProductRelation`,
            name: '秒杀商品列表',
            meta: { hidden: true },
          },
          {
            path: `${basePath}/sms/coupon`,
            name: '优惠券列表',
          },
          {
            path: `${basePath}/sms/couponAudit`,
            name: '优惠券审核',
          },
          {
            path: `${basePath}/sms/couponAuditHistory`,
            name: '优惠券领取详情',
            meta: { hidden: true },
          },
          {
            path: `${basePath}/sms/addCoupon`,
            name: '添加优惠券',
            meta: { hidden: true },
          },
          {
            path: `${basePath}/sms/updateCoupon`,
            name: '添加优惠券',
            meta: { hidden: true },
          },
          {
            path: `${basePath}/sms/couponHistory`,
            name: '优惠券领取详情',
            meta: { hidden: true },
          },
          {
            path: `${basePath}/sms/brand`,
            name: '品牌推荐',
          },
          {
            path: `${basePath}/sms/new`,
            name: '新品推荐',
          },
          {
            path: `${basePath}/sms/hot`,
            name: '人气推荐',
          },
          {
            path: `${basePath}/sms/subject`,
            name: '专题推荐',
          },
          {
            path: `${basePath}/sms/advertise`,
            name: '广告列表',
          },
          {
            path: `${basePath}/sms/addAdvertise`,
            name: '添加广告',
            meta: { hidden: true },
          },
          {
            path: `${basePath}/sms/updateAdvertise`,
            name: '编辑广告',
            meta: { hidden: true },
          },
          {
            path: `${basePath}/sms/button`,
            name: '导航配置',
          },
          {
            path: `${basePath}/sms/addButton`,
            name: '添加导航',
            meta: { hidden: true },
          },
          {
            path: `${basePath}/sms/updateButton`,
            name: '修改导航',
            meta: { hidden: true },
          },
          {
            path: `${basePath}/sms/index`,
            name: '首页配置',
          },
          {
            path: `${basePath}/sms/selectCustomProduct`,
            name: '绑定自定义模块商品',
            meta: { hidden: true },
          },
          {
            path: `${basePath}/sms/activity`,
            name: '活动管理',
          },
          {
            path: `${basePath}/sms/selectActivityProduct`,
            name: '绑定活动商品',
            meta: { hidden: true },
          },
          {
            path: `${basePath}/sms/ladder`,
            name: '阶梯价格',
          },
          {
            path: `${basePath}/sms/fullReduction`,
            name: '满减价格',
          },
          {
            path: `${basePath}/sms/group`,
            name: '团购管理',
          },
          {
            path: `${basePath}/sms/addGroup`,
            name: '新增团购',
            meta: { hidden: true },
          },
          {
            path: `${basePath}/sms/updateGroup`,
            name: '修改团购',
            meta: { hidden: true },
          },
        ],
      },
    ],
  },
];
export default router;
