import { services, setAxios } from '@lowinc/billbear-usercenter-apis';
import request from '@/utils/request';
setAxios(request);

export const menuApi = {
  getMenuInfoById: services.getSysMenuInfoId,
  addMenu: services.postSysMenuAdd,
  editMenu: services.putSysMenuEdit,
  getUserBaseInfo: services.postSysMenuPerm,
  getMenuList: services.postSysMenuTree,
  deleteMenuById: services.deleteSysMenuDeleteId,
};

export const userApi = {
  getUserListByPage: services.postSysUserPages,
  userDisableByIds: services.putSysUserStops,
  userEnableByIds: services.putSysUserEnables,
  /**
   * 系统管理员：新增用户
   */
  addUser: services.postSysUserAdd,
  /**
   * 系统管理员：编辑用户
   */
  editUser: services.putSysUserEdit,
  /**
   * 客户/供应商管理员：新增用户
   */
  addCompanyUser: services.postSysUserAddCompanyUser,
  /**
   * 客户/供应商管理员：编辑用户
   */
  editCompanyUser: services.putSysUserEditCompanyUser,

  getUserRolesByUserId: services.getSysUserRoleListUserId,
  getUserMenuListByUserId: services.getSysUserMenuListUserId,
  editUserMenu: services.postSysUserMenuEdit,
  getUserDataPermissionListByUserId: services.getSysUserDataListUserId,
  editUserDataPermission: services.postSysUserDataEdit,
  checkMobileIsUnique: services.getSysUserCheckMobileIsUniqueMobile,
};

export const deptApi = {
  getDeptTree: services.postSysDeptTree,
  getDeptTreeByCompanyId: services.postSysDeptObtainDepartmentDeptId,
  addDept: services.postSysDeptAdd,
  deleteById: services.deleteSysDeptDeleteId,
  updateDept: services.putSysDeptEdit,
};

export const roleApi = {
  getAllRoles: services.postSysRolePages,
  addRole: services.postSysRoleAdd,
  editRole: services.putSysRoleEdit,
  deleteRoleById: services.deleteSysRoleDeleteId,
  getRoleMenuListByRoleId: services.getSysRoleMenuListRoleId,
  editRoleMenu: services.postSysRoleMenuEdit,
  getRoleDataPermissionListByRoleId: services.getSysRoleDataListRoleId,
  editRoleDataPermission: services.postSysRoleDataEdit,
  getRoleInfo(id?: string) {
    return request.get(`/usercenter/sys/user/info/${id}`);
  },
};
