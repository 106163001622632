export default [
  {
    name: 'scma', // 供应链管理系统
    entry: '/apps/supply-chain/',
    container: '#app-qiankun',
    activeRule: '/scma',
  },
  {
    name: 'billbear-supply-chain-manager-admin', // 供应链管理系统(新)
    entry: '/apps/supply-chain-admin/',
    // entry: 'http://localhost:8083/',
    container: '#app-qiankun',
    activeRule: '/sc',
  },
  {
    name: 'sma', // 供应商管理系统
    entry: '/apps/supplier/',
    container: '#app-qiankun',
    activeRule: '/sma',
  },
  {
    name: 'ffma', // 吃货一号管理系统
    entry: '/apps/foodie-first/',
    container: '#app-qiankun',
    activeRule: '/ffma',
  },
  {
    name: 'mall', // 外包积分商城
    entry: '/apps/imall/',
    container: '#app-qiankun',
    activeRule: '/mall',
  },
  {
    name: 'operate', // 运营系统
    entry: '/apps/operate-sys/',
    container: '#app-qiankun',
    activeRule: '/operate',
  },
  {
    name: 'customer', // 客户管理系统
    entry: '/apps/customer-sys/',
    container: '#app-qiankun',
    activeRule: '/customer',
  },
  {
    name: 'billbear-customer-manager-admin', // 客户管理系统(新)
    entry: '/apps/customer-admin/',
    container: '#app-qiankun',
    activeRule: '/cst',
  },
  {
    name: 'coral-share-card', // 珊瑚共享卡管理系统
    entry: '/apps/coral-share-card/',
    container: '#app-qiankun',
    activeRule: '/coral-share-card',
  },
];
