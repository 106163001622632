<template>
  <div class="logo">
    <img src="@/assets/logo2.png" alt="logo" />
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.logo {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  img {
    width: 145px;
    height: auto;
    max-width: initial;
  }
}
</style>
