<template>
  <div class="flex items-center">
    <hamburger
      :is-active="sidebar.opened"
      class="hamburger-container"
      @toggleClick="toggleSideBar"
    />
    <div
      v-if="device != 'mobile'"
      class="flex flex-col items-start justify-center"
    >
      <div class="pb-10 font-bold text-18">
        {{
          (breadcrumbListData.length > 0 &&
            breadcrumbListData[breadcrumbListData.length - 1].name.replace(
              breadcrumbListData[breadcrumbListData.length - 1].path,
              '',
            )) ||
            ''
        }}
      </div>
      <transition name="fade-transform" mode="out-in">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item
            v-for="route in breadcrumbListData"
            :key="route.path"
          >
            {{ route.name.replace(route.path, '') }}
          </el-breadcrumb-item>
        </el-breadcrumb>
      </transition>
    </div>
  </div>
</template>

<script>
import Hamburger from '@/components/Hamburger';
import { mapGetters, mapState } from 'vuex';
import ResizeMixin from '../layout/mixin/ResizeHandler';
import EventBus from '@/utils/EventBus';
export default {
  mixins: [ResizeMixin],
  components: {
    Hamburger,
  },
  data() {
    return {
      indexBreadcrumbs: [],
      breadcrumbListData: [],
    };
  },
  computed: {
    ...mapGetters(['sidebar']),
    device() {
      return this.$store.state.app.device;
    },
    routePath() {
      return this.$route.path;
    },
    routeList() {
      return this.$route.matched;
    },
    title() {
      return this.$route.name;
    },
    ...mapState({
      routes: state => state.addRouters,
    }),
  },
  watch: {
    // 监听路由的改变(实时改变面包屑数据)
    $route() {
      this.breadcrumbList();
    },
  },
  mounted() {
    // 监听菜单变化，获取当前菜单path
    EventBus.on('handleSelectMenu', indexPath => {
      this.indexBreadcrumbs = indexPath;
      this.breadcrumbList();
    });

    this.breadcrumbList();
    // console.log(this.selectedKeys)
    // console.log(this.$route);
  },
  methods: {
    toggleSideBar() {
      this.$store.dispatch('app/toggleSideBar');
    },
    // 获取面包屑数据(将选中菜单的数据进行处理,得到面包屑的数据)
    breadcrumbList() {
      const breadcrumbs = [];
      let menuList = this.routes;
      this.indexBreadcrumbs.map(item => {
        for (let i = 0; i < menuList.length; i++) {
          if (item === menuList[i].path) {
            breadcrumbs.push(menuList[i]);
            if (menuList[i].children) {
              menuList = menuList[i].children;
            }
            break;
          }
        }
      });
      this.breadcrumbListData = breadcrumbs; // 返回的一个数组，里面只包含选中的菜单
    },
  },
};
</script>

<style lang="less" scoped>
.font-bold {
  font-weight: 700;
}
.hamburger-container {
  display: flex;
  align-items: center;
  // line-height: 46px;
  height: 100%;
  // float: left;
  cursor: pointer;
  transition: background 0.3s;
  -webkit-tap-highlight-color: transparent;

  &:hover {
    background: rgba(0, 0, 0, 0.025);
  }
}
</style>
