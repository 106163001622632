export default{
  update(el,bindings) {
    const [defaultValues,selectValue] = bindings.value;
    //需要隐藏的标签索引
    const indexs = []
    selectValue.map((item,index) => {
      defaultValues.map((defaultItem) => {
        if(item === defaultItem) {
          indexs.push(index)
        }
      });
    });

    const dealStyle = (tags) =>{
      tags.forEach((el,index) => {
        if(indexs.includes(index) && ![...el.classList].includes('select-tag-close-none')) {
          el.style.display = 'none' // close 图标隐藏掉
        }
      });
    };
    
    const tags = el.querySelectorAll('.el-tag__close');
    // 初始化tags为空处理
    if(tags.length === 0) {
      setTimeout(() => {
        const tagTemp = el.querySelectorAll('.el-tag__close')
        dealStyle(tagTemp);
      })
    } else {
      setTimeout(() => {
        dealStyle(tags);
      })
    }
  }
}