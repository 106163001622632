import { RouteConfig } from 'vue-router';
import Layout from '@/layout/index.vue';
const basePath = `/operate/apps/operate-sys`;
const router: Array<RouteConfig> = [
  {
    path: `${basePath}/couponManagement`,
    name: `优惠券管理`,
    component: Layout,
    children: [
      {
        name: `优惠券列表`,
        path: `${basePath}/coupon/list`,
      },
      {
        name: `创建优惠券`,
        path: `${basePath}/coupon/add`,
        meta: { hidden: true },
      },
      {
        name: `编辑优惠券`,
        path: `${basePath}/coupon/edit/:id/:status`,
        meta: { hidden: true },
      },
      {
        name: `优惠券审核`,
        path: `${basePath}/coupon/check`,
      },
      {
        name: `优惠券-选品`,
        path: `${basePath}/coupon/selection/:id/:status`,
        meta: { hidden: true },
      },
    ],
  },
];
export default router;
