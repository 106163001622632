import { RouteConfig } from 'vue-router';
import Layout from '@/layout/index.vue';
const basePath = `/ffma/apps/foodie-first`;
const router: Array<RouteConfig> = [
  {
    path: basePath,
    name: '吃货一号',
    component: Layout,
    children: [
      {
        path: `${basePath}/merchant-manage`,
        name: '商家管理',
        children: [
          {
            path: `${basePath}/merchant-manage/list`,
            name: '商家列表',
          },
          {
            path: `${basePath}/merchant-manage/add`,
            name: '新建商家',
            meta: { hidden: true },
          },
          {
            path: `${basePath}/merchant-manage/edit`,
            name: '编辑商家',
            meta: { hidden: true },
          },
          {
            path: `${basePath}/merchant-manage/store/list`,
            name: '门店列表',
          },
          {
            path: `${basePath}/merchant-manage/administrators`,
            name: '管理员',
            meta: { hidden: true },
          },
          {
            path: `${basePath}/merchant-manage/record`,
            name: '核销记录',
            meta: { hidden: true },
          },
        ],
      },
      {
        path: `${basePath}/goods-manage`,
        name: '商品管理',
        children: [
          {
            path: `${basePath}/goods-manage/list`,
            name: '商品列表',
          },
          {
            path: `${basePath}/goods-manage/add`,
            name: '商品添加',
            meta: { hidden: true },
          },
          {
            path: `${basePath}/goods-manage/edit`,
            name: '商品编辑',
            meta: { hidden: true },
          },
          {
            path: `${basePath}/goods-manage/verification`,
            name: '核销码管理',
            meta: { hidden: true },
          },
        ],
      },
      {
        path: `${basePath}/verify-manage`,
        name: '核销管理',
        children: [
          {
            path: `${basePath}/verify-manage/list`,
            name: '核销列表',
          },
        ],
      },
      {
        path: `${basePath}/settlement`,
        name: '结算管理',
        children: [
          {
            path: `${basePath}/settlement/list`,
            name: '结算列表',
          },
        ],
      },
    ],
  },
];
export default router;
