/**
 * 格式化树形数据, 适配 el-cascader 组件
 */
export function formatTree(treeList: any[]): any[] {
  return treeList.map(item => {
    if (item?.children?.length > 0) {
      return { ...item, children: formatTree(item.children) };
    }
    // 没有子数据时, children 必须为 null 不能为 []
    item.children = null;
    return item;
  });
}
/**
 * 过滤树形数据
 * @param treeList
 * @param customizer
 */
export function filterTree(
  treeList: any[],
  customizer: (value: any) => boolean,
): any[] {
  if (!treeList?.length) return [];
  return treeList.filter(item => {
    if (item?.children?.length) {
      item.children = filterTree(item?.children, customizer);
      if (item?.children?.length) return true;
    }
    return customizer(item);
  });
}

/**
 * source的属性如果存在于object中，就赋值给 object
 * @param object
 * @param source
 */
export function assign(object: any, source: any) {
  for (const key in object) {
    if (key in source) {
      object[key] = source[key];
    }
  }
}

/**
 * 将数据数据解构成list
 * @param treeList
 */
export function treeToList(treeList: any[]): any[] {
  return treeList.reduce((list, item) => {
    if (item.children) {
      list = list.concat(treeToList(item.children));
    }
    list.push({ ...item, children: null });
    return list;
  }, []);
}
