import axios from 'axios';
const isNewVersion = () => {
  if (process.env.NODE_ENV === 'production') {
    const url = `//${
      window.location.host
    }/version.json?t=${new Date().getTime()}`;
    axios.get(url).then(res => {
      if (res.status === 200) {
        const vueVersion = res.data.version || '1.0.0';
        const localVueVersion = localStorage.getItem('manager-version');
        localStorage.setItem('manager-version', vueVersion);
        if (
          localVueVersion &&
          localVueVersion != vueVersion &&
          window.location.pathname != '/login'
        ) {
          alert('检测到新版本，请点击确认刷新页面哦');
          window.location.reload(true);
          return;
        }
      }
    });
  }
};

export default {
  isNewVersion,
};
