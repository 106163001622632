<template>
  <section :class="{ 'app-main__qiankun': true, mini: device === 'mobile' }">
    <transition name="fade-transform" mode="out-in">
      <router-view :key="key" />
    </transition>
    <div id="app-qiankun" />
  </section>
</template>

<script>
import qiankunStart from '@/micro';
import { mapState } from 'vuex';
export default {
  name: 'AppMain',
  computed: {
    ...mapState({
      device: state => state.app.device,
    }),
    key() {
      return this.$route.path;
    },
  },
  mounted() {
    if (!window.__POWERED_BY_QIANKUN__) {
      console.log('qiankun start');
      qiankunStart();
    }
  },
};
</script>

<style scoped>
.app-main__qiankun {
  min-height: calc(100% - 80px);
  max-height: calc(100% - 80px);
  overflow: auto;
  background-color: #f0f2f5;
  color: #303133;
  transition: 0.3s;
  box-sizing: border-box;
  text-align: left;
}
.mini {
  width: 768px;
  overflow: auto;
}
</style>
