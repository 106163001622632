<template>
  <div class="flex items-center justify-between navbar">
    <breadcrumb />
    <div class="flex items-center">
      <supplier
        :supplierType="supplierType"
        :supplierPaths="supplierPaths"
      ></supplier>
      <div class="mr-20" v-show="isShowCustomer">
        <span class="text-14">客户: </span>
        <el-select
          :disabled="!$store.state.isPartnerSelectAble"
          placeholder="请选择客户"
          filterable
          v-model="partnerId"
        >
          <el-option
            v-for="item of partnerList"
            :key="item.id"
            :label="item.customerName"
            :value="item.id"
          />
        </el-select>
      </div>

      <el-dropdown class="dropdown-container" @command="handleCommand">
        <div class="account">
          <el-avatar :size="30" :src="userAvatar" class="avatar-wrapper" />
          <span class="account-name">{{ userName }}</span>
          <i class="el-icon-arrow-down el-icon--right" />
        </div>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="logout"> 退出登录 </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex';
import Breadcrumb from '../components/Breadcrumb.vue';
import actions from '../micro/actions';
import supplier from './components/supplier';
export default {
  name: 'Navbar',
  data() {
    return {
      partnerId: this.$store.state.partnerId,
      supplierPaths: {
        Supplier: [
          '/scma/apps/supply-chain/inventory/publish',
          '/scma/apps/supply-chain/inventory/list',
        ],
        SaleSupplier: ['/scma/apps/supply-chain/inventory/list'],
        VouerSupplier: ['/scma/apps/supply-chain/vouchercenter/publish'],
        StockSupplier: [
          '/scma/apps/supply-chain/vouchercenter/inventoryManagement',
        ],
        HotelSupplier: ['/scma/apps/supply-chain/hotel/list'],
      },
    };
  },
  components: { Breadcrumb, supplier },
  watch: {
    partnerId(value) {
      this.setPartnerId(value);
      actions.setGlobalState({
        type: 'partnerId',
        payload: value,
      });
    },
    '$store.state.partnerId'(value) {
      if (this.partnerId !== value) {
        this.partnerId = value;
      }
    },
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo,
      partnerList: state => state.partnerList,
      sidebar: state => state.app.sidebar,
      device: state => state.app.device,
    }),
    userName() {
      return this.userInfo.userName;
    },
    userAvatar() {
      return this.userInfo.avatar;
    },
    /**
     * 显示客户选择框的路由
     * 需要处理路径参数的路由情况 /a/b/c/:id
     */
    isShowCustomer() {
      return [
        '/customer/apps/customer-sys/goods/list',
        '/customer/apps/customer-sys/goods-group/list',
        '/customer/apps/customer-sys/goods-group/edit',
        '/customer/apps/customer-sys/classify/list',
        '/customer/apps/customer-sys/super-payment/list',
        '/customer/apps/customer-sys/super-payment/order',
        '/customer/apps/customer-sys/super-payment/home',
        '/customer/apps/customer-sys/vip/list',
        '/customer/apps/customer-sys/interest/list',
        '/customer/apps/customer-sys/interest-card/list',
        '/customer/apps/customer-sys/vip/cardsellcfg',
        '/customer/apps/customer-sys/coupon/issuance',
        '/customer/apps/customer-sys/trade/order/list',
        '/customer/apps/customer-sys/trade/rightscardorder/list',
        '/customer/apps/customer-sys/trade/topUpOrder/list',
        '/customer/apps/customer-sys/trade/hotelOrders/list',
        '/customer/apps/customer-sys/trade/bookOrders/list',
        '/customer/apps/customer-sys/system/login',
        '/customer/apps/customer-sys/system/cashier',
        '/customer/apps/customer-sys/system/agreement',
        '/customer/apps/customer-sys/client-manage/manage',
        '/customer/apps/customer-sys/secondsKill/list',
        '/customer/apps/customer-sys/secondsKill/statistics',
        '/customer/apps/customer-sys/secondsKill/flashsale',
        '/customer/apps/customer-sys/rechargeCentre/list',
        '/customer/apps/customer-sys/marketingManagement/list',
        '/customer/apps/customer-sys/customConfiguration/index',
        '/customer/apps/customer-sys/customDomainName/index',
        '/customer/apps/customer-sys/free-ticket/freeManage',
        '/customer/apps/customer-sys/free-ticket/getRecord',
        '/customer/apps/customer-sys/hotelManagement/hotelSetting',
        '/customer/apps/customer-sys/reservation-center/list',
        '/customer/apps/customer-sys/pointsMall/pointsGoods/list',
        '/customer/apps/customer-sys/pointsMall/pointsSet',
        '/customer/apps/customer-sys/pointsMall/pointsList',
        '/customer/apps/customer-sys/pointsMall/transaction/orderList',
        '/cst/apps/customer-admin/operate/coupon/index',
        '/cst/apps/customer-admin/operate/coupon/activity/index',
        '/cst/apps/customer-admin/operate/crowd-operation/index',
        '/cst/apps/customer-admin/operate/activation-code/index',
        '/customer/apps/customer-sys/form-tool/list',
        '/customer/apps/customer-sys/system/payment-config',
        '/customer/apps/customer-sys/trade/ecardActivation/index',
        '/customer/apps/customer-sys/third-app/list',
        '/customer/apps/customer-sys/basicConfiguration/devConfig',
        '/customer/apps/customer-sys/basicConfiguration/accountInfo',
        '/customer/apps/customer-sys/customMade/list',
        '/customer/apps/customer-sys/operationManagement/list',
        '/cst/apps/customer-admin/member-management/interest',
        '/cst/apps/customer-admin/basic/dev-config',
        '/cst/apps/customer-admin/basic/account',
        '/cst/apps/customer-admin/basic/agreement-configuration',
        '/cst/apps/customer-admin/basic/login-configuration',
        '/cst/apps/customer-admin/member-management/card-configuration',
        '/cst/apps/customer-admin/member-management/interest-card',
        '/cst/apps/customer-admin/member-management/vip',
        '/cst/apps/customer-admin/operate/form-tool/list'
      ].some(path => {
        Object.keys(this.$route.params).forEach(key => {
          path = path.replace(
            new RegExp('(:' + key + ')', 'g'),
            this.$route.params[key],
          );
        });
        let flag = true;
        if (this.sidebar.opened && this.device === 'mobile') {
          flag = false;
        }
        return path === decodeURI(this.$route.path) && flag;
      });
    },
    supplierType() {
      for (const key in this.supplierPaths) {
        for (const value of this.supplierPaths[key]) {
          if (value === this.$route.path) {
            return key;
          }
        }
      }
      return '';
    },
  },
  methods: {
    ...mapMutations(['setPartnerId']),
    ...mapActions(['logout']),
    handleCommand(command) {
      if (command === 'logout') {
        this.logout();
      }
    },
    toggleSideBar() {
      this.$store.dispatch('app/toggleSideBar');
    },
  },
};
</script>

<style lang="less" scoped>
.navbar {
  position: relative;
  overflow: hidden;
  height: 100%;
  width: 100%;
  .dropdown-container {
    min-width: 110px;
    height: 100%;
    float: right;
    text-align: left;
    display: flex;
    .avatar-wrapper {
      margin-right: 10px;
    }
    .account {
      display: flex;
      align-items: center;
      color: #333;
    }
  }
}
</style>
