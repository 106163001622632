import defaultSelect from './defaultSelect.js'
import Vue from 'vue';
const install = () => {
    Vue.directive('defaultSelect', defaultSelect)
}

if (window.Vue) {
    Vue.use(install)
}

export default install