<template>
  <el-container :class="classObj" class="layout-container__qiankun">
    <div
      v-if="device === 'mobile' && sidebar.opened"
      class="drawer-bg"
      @click="handleClickOutside"
    />
    <el-container class="layout-content">
      <el-aside :width="asideWidth">
        <el-header class="logo-header">
          <logo />
        </el-header>
        <sidebar class="sidebar-container" />
      </el-aside>
      <el-main class="layout-main">
        <el-header
          :class="{ 'layout-header': true, mini: device === 'mobile' }"
          height="80px"
        >
          <navbar />
        </el-header>
        <app-main />
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import Sidebar from './Sidebar';
import AppMain from './AppMain.vue';
import Navbar from './Navbar.vue';
import Logo from './Logo.vue';
import { mapState } from 'vuex';
import ResizeMixin from './mixin/ResizeHandler';
export default {
  name: 'Layout',
  components: {
    Sidebar,
    AppMain,
    Navbar,
    Logo,
  },
  mixins: [ResizeMixin],
  data() {
    return {
      inlineCollapsed: true,
      collapsed: false,
    };
  },
  methods: {
    handleClickOutside() {
      this.$store.dispatch('app/closeSideBar', { withoutAnimation: false });
    },
  },
  computed: {
    ...mapState({
      sidebar: state => state.app.sidebar,
      device: state => state.app.device,
      // showSettings: state => state.settings.showSettings,
      // needTagsView: state => state.settings.tagsView,
      // fixedHeader: state => state.settings.fixedHeader
    }),
    classObj() {
      return {
        hideSidebar: !this.sidebar.opened,
        openSidebar: this.sidebar.opened,
        withoutAnimation: this.sidebar.withoutAnimation,
        mobile: this.device === 'mobile',
      };
    },
    asideWidth() {
      return !this.sidebar.opened && this.device != 'mobile'
        ? '54px'
        : !this.sidebar.opened && this.device === 'mobile'
        ? '0'
        : '200px';
    },
  },
};
</script>
<style lang="less" scoped>
.layout-container__qiankun {
  height: 100%;
  .logo-header {
    background-color: #fff;
    height: 80px !important;
    padding: 0 14px;
  }
  .layout-header {
    padding: 0 20px;
    justify-content: space-between;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    background-color: #fff;
  }
  .drawer-bg {
    background: #000;
    opacity: 0.3;
    width: 100%;
    top: 0;
    height: 100%;
    position: absolute;
    z-index: 999;
  }

  .layout-content {
    box-sizing: border-box;
    overflow: hidden;
    .layout-main {
      padding: 0;
      overflow: auto;
    }
  }
  .mini {
    width: 768px;
    overflow: auto;
  }
}
</style>
