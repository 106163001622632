export const STATUS = {
  DELETE: '0',
  NORMAL: '1',
  STOP: '2',
};
export const STATUS_TEXT = {
  [STATUS.NORMAL]: '启用',
  // [STATUS.DELETE]: '删除',
  [STATUS.STOP]: '停用',
};
export const USER_TYPE = {
  SYS_MANAGER: '0',
  PARTNER_MANAGER: '1',
  SUPPLIER_MANAGER: '2',
};
export const USER_TYPE_TEXT = {
  [USER_TYPE.SYS_MANAGER]: '系统账户',
  [USER_TYPE.PARTNER_MANAGER]: '客户管理员',
  [USER_TYPE.SUPPLIER_MANAGER]: '供货商管理员',
};
export const DEPT_TYPE = {
  PLATFORM: '0',
  PARTNER: '1',
  SUPPLIER: '2',
  DEPT: '3',
};
export const DEPT_TYPE_TEXT = {
  [DEPT_TYPE.PLATFORM]: '平台',
  [DEPT_TYPE.PARTNER]: '客户',
  [DEPT_TYPE.SUPPLIER]: '供应商',
  [DEPT_TYPE.DEPT]: '部门',
};

export const MENU_TYPE = {
  DIR: '0',
  MENU: '1',
  BUTTON: '2',
};
export const MENU_TYPE_TEXT = {
  [MENU_TYPE.DIR]: '目录',
  [MENU_TYPE.MENU]: '菜单',
  [MENU_TYPE.BUTTON]: '按钮',
};

export const TAG_TYPE = {
  [MENU_TYPE.DIR]: '',
  [MENU_TYPE.MENU]: 'success',
  [MENU_TYPE.BUTTON]: 'warning',
};

export const MENU_VISIBLE = {
  SHOW: '1',
  HIDE: '0',
};
export const MENU_STATUS = {
  DELETE: '0',
  NORMAL: '1',
  DISABLED: '2',
};
export const MENU_STATUS_TEXT = {
  [MENU_STATUS.DELETE]: '删除',
  [MENU_STATUS.NORMAL]: '启用',
  [MENU_STATUS.DISABLED]: '禁用',
};

export const PERMISSION_TYPE = {
  NONE: 'NO_PERMISSION',
  ALL: 'ALL_PERMISSION',
  SELECT: 'ASSIGN_PERMISSION',
};

export const DOMAIN_NAME = [
  {
    hostname: '66n.co',
    data: {
      logo: '',
      shortName: '闪咛宁网络',
      fullName: '北京闪咛宁网络技术有限公司',
      email: 'kefu@66n.co',
      copyright: '2015-2020',
      websiteName: '首页',
      websiteId: '京ICP备2021040126号-2',
    },
  },

  {
    hostname: 'aomaowl.com',
    data: {
      logo: '',
      shortName: '奥茂网络',
      fullName: '上海奥茂网络科技有限公司',
      email: 'kefu@aomaowl.com',
      copyright: '2015-2020',
      websiteName: '首页',
      websiteId: '沪ICP备2021013162号-1',
    },
  },

  {
    hostname: 'billbear.vip',
    data: {
      logo: '',
      shortName: '烈熊网络',
      fullName: '上海烈熊网络技术有限公司',
      email: 'kefu@billbear.vip',
      copyright: '2015-2020',
      websiteName: '首页',
      websiteId: '沪ICP备15043205号-7',
    },
  },

  {
    hostname: 'huaynet.cn',
    data: {
      logo: '',
      shortName: '花荧网络',
      fullName: '上海花荧网络技术有限公司',
      email: 'kefu@huaynet.cn',
      copyright: '2015-2020',
      websiteName: '首页',
      websiteId: '沪ICP备2022020518号-1',
    },
  },

  {
    hostname: 'jimannet.com',
    data: {
      logo: '',
      shortName: '级嫚网络',
      fullName: '盐城级嫚网络技术有限公司',
      email: 'kefu@jimannet.com',
      copyright: '2015-2020',
      websiteName: '首页',
      websiteId: '苏ICP备2022029386号-1',
    },
  },

  {
    hostname: 'jinzhangnet.com',
    data: {
      logo: '',
      shortName: '晋彰网络',
      fullName: '成都晋彰网络技术有限公司',
      email: 'kefu@jinzhangnet.com',
      copyright: '2015-2020',
      websiteName: '首页',
      websiteId: '蜀ICP备2022019130号-1',
    },
  },

  {
    hostname: 'kenrnet.cn',
    data: {
      logo: '',
      shortName: '肯熔网络',
      fullName: '合肥肯熔网络科技有限公司',
      email: 'kefu@kenrnet.cn',
      copyright: '2015-2020',
      websiteName: '首页',
      websiteId: '皖ICP备2022017132号-1',
    },
  },

  {
    hostname: 'liezhennet.com',
    data: {
      logo: '',
      shortName: '烈臻网络',
      fullName: '海南烈榛网络科技有限公司',
      email: 'kefu@liezhennet.com',
      copyright: '2015-2020',
      websiteName: '烈榛网络',
      websiteId: '琼ICP备2022007605号-1',
    },
  },

  {
    hostname: 'qinjnet.com',
    data: {
      logo: '',
      shortName: '秦嘉网络',
      fullName: '上海秦嘉网络科技有限公司',
      email: 'kefu@qinjnet.com',
      copyright: '2015-2020',
      websiteName: '首页',
      websiteId: '渝ICP备2022011500号-1',
    },
  },

  {
    hostname: 'shannn.cn',
    data: {
      logo: '',
      shortName: '闪咛宁网络',
      fullName: '北京闪咛宁网络技术有限公司',
      email: 'kefu@shannn.cn',
      copyright: '2015-2020',
      websiteName: '首页',
      websiteId: '京ICP备2021040126号-1',
    },
  },

  {
    hostname: 'shanrongnet.com',
    data: {
      logo: '',
      shortName: '闪熔网络',
      fullName: '海南闪熔网络技术有限公司',
      email: 'kefu@shanrongnet.com',
      copyright: '2015-2020',
      websiteName: '首页',
      websiteId: '琼ICP备2022007618号-1',
    },
  },

  {
    hostname: 'shantaiwl.com',
    data: {
      logo: '',
      shortName: '闪态网络',
      fullName: '上海闪态网络技术有限公司',
      email: 'kefu@shantaiwl.com',
      copyright: '2015-2020',
      websiteName: '首页',
      websiteId: '沪ICP备20004320号-1',
    },
  },

  {
    hostname: 'shantaiwl.cn',
    data: {
      logo: '',
      shortName: '闪态网络',
      fullName: '上海闪态网络技术有限公司',
      email: 'kefu@shantaiwl.cn',
      copyright: '2015-2020',
      websiteName: '闪态官网',
      websiteId: '沪ICP备20004320号-2',
    },
  },

  {
    hostname: 'xleike.cn',
    data: {
      logo: '',
      shortName: '讯磊网络',
      fullName: '海南讯磊科技技术有限公司',
      email: 'kefu@xleike.cn',
      copyright: '2015-2020',
      websiteName: '讯磊官网',
      websiteId: '琼ICP备2022010745号-1',
    },
  },

  {
    hostname: 'huangcnet.cn',
    data: {
      logo: '',
      shortName: '晃慈网络',
      fullName: '合肥晃慈信息科技有限公司',
      email: 'kefu@huangcnet.cn',
      copyright: '2015-2020',
      websiteName: '合肥晃慈',
      websiteId: '皖ICP备2023000804号-1',
    },
  },

  {
    hostname: 'gengcnet.cn',
    data: {
      logo: '',
      shortName: '耿慈网络',
      fullName: '合肥耿慈信息科技有限公司',
      email: 'kefu@gengcnet.cn',
      copyright: '2015-2020',
      websiteName: '合肥耿慈',
      websiteId: '皖ICP备2023000846号-1',
    },
  },

  {
    hostname: 'shanghaibinyu.top',
    data: {
      logo: '',
      shortName: '槟宇网络',
      fullName: '上海槟宇网络科技有限公司',
      email: 'kefu@shanghaibinyu.top',
      copyright: '2015-2020',
      websiteName: '上海槟宇',
      websiteId: '沪icp备2022026809号-1',
    },
  },

  {
    hostname: 'tinganet.cn',
    data: {
      logo: '',
      shortName: '庭桉网络',
      fullName: '合肥庭桉网络科技传媒有限公司',
      email: 'kefu@tinganet.cn',
      copyright: '2015-2020',
      websiteName: '合肥庭桉',
      websiteId: '皖ICP备2023003998号-1',
    },
  },

  {
    hostname: 'xunleizhenxuan.com',
    data: {
      logo: '',
      shortName: '讯磊甄选',
      fullName: '海南讯磊科技有限公司',
      email: 'kefu@xunleizhenxuan.com',
      copyright: '2015-2020',
      websiteName: '讯磊甄选',
      websiteId: '琼ICP备2022010745号-2',
    },
  },

  {
    hostname: 'chunjnet.cn',
    data: {
      logo: '',
      shortName: '纯精网络',
      fullName: '上海纯精网络技术有限公司',
      email: 'kefu@chunjnet.cn',
      copyright: '2015-2020',
      websiteName: '纯精网络',
      websiteId: '沪ICP备2023013248号',
    },
  },

  {
    hostname: 'chunsnet.cn',
    data: {
      logo: '',
      shortName: '纯韶网络',
      fullName: '上海纯韶网络技术有限公司',
      email: 'kefu@chunsnet.cn',
      copyright: '2015-2020',
      websiteName: '纯韶网络',
      websiteId: '沪ICP备2023013196号',
    },
  },

  {
    hostname: 'zhuihnet.cn',
    data: {
      logo: '',
      shortName: '追辉科技',
      fullName: '上海追辉科技有限公司',
      email: 'kefu@zhuihnet.cn',
      copyright: '2015-2020',
      websiteName: '追辉科技',
      websiteId: '沪ICP备2023013277号',
    },
  },

  {
    hostname: 'mawnet.cn',
    data: {
      logo: '',
      shortName: '盐城马舞',
      fullName: '盐城马舞网络科技有限公司',
      email: 'kefu@mawnet.cn',
      copyright: '2015-2020',
      websiteName: '盐城马舞',
      websiteId: '苏ICP备2023016244号-1',
    },
  },

  // TODO 1scrm.com 和 1scrm.cn
];
