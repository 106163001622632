import Cookies from 'js-cookie';
const app = {
  namespaced: true,
  state: {
    list: [],
    sidebar: {
      opened: Cookies.get('sidebarStatus')
        ? !!+(Cookies.get('sidebarStatus') as any)
        : true,
      withoutAnimation: false,
    },
    device: 'desktop',
  },
  mutations: {
    setData: (state: any, list: any[]) => {
      state.list = list;
    },
    TOGGLE_SIDEBAR: (state: any) => {
      state.sidebar.opened = !state.sidebar.opened;
      state.sidebar.withoutAnimation = false;
      if (state.sidebar.opened) {
        Cookies.set('sidebarStatus', '1');
      } else {
        Cookies.set('sidebarStatus', '0');
      }
    },

    TOGGLE_DEVICE: (state: any, device: any) => {
      state.device = device;
    },

    CLOSE_SIDEBAR: (state: any, withoutAnimation: any) => {
      Cookies.set('sidebarStatus', '0');
      state.sidebar.opened = false;
      state.sidebar.withoutAnimation = withoutAnimation;
    },
    OPEN_SIDEBAR: (state: any, withoutAnimation: any) => {
      Cookies.set('sidebarStatus', '1');
      state.sidebar.opened = true;
      state.sidebar.withoutAnimation = withoutAnimation;
    },
  },
  actions: {
    fetchData({ commit }: any) {
      commit('setData', []);
    },
    toggleSideBar({ commit }: any) {
      commit('TOGGLE_SIDEBAR');
    },
    toggleDevice({ commit }: any, device: any) {
      commit('TOGGLE_DEVICE', device);
    },
    closeSideBar({ commit }: any, { withoutAnimation }: any) {
      commit('CLOSE_SIDEBAR', withoutAnimation);
    },
    openSideBar({ commit }: any, { withoutAnimation }: any) {
      commit('OPEN_SIDEBAR', withoutAnimation);
    },
  },
};
export default app;
