import request from '@/utils/request';
export const promotionAPi = {
  // 分页查询推广位
  findPages(params: any) {
    return request.post('/baseConfig/promote/findPages', params);
  },
  // 创建推广位
  create(params: any) {
    return request.post('/baseConfig/promote/create', params);
  },
  // 更新推广位
  update(params: any) {
    return request.post('/baseConfig/promote/update', params);
  },
};
