import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Layout from '../layout/index.vue';
import emptyLayout from '../layout/EmptyLayout.vue';
import { ACCESS_TOKEN } from '@/constants/storage';
import microAppRouter from './micro-app';
import storage from 'store';
import store from '@/store';
import { treeToList } from '@/utils/tools';
import { createMenus } from '@/utils/router';
import versionTood from '@/utils/versionUpdate';
Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/permissions',
    component: emptyLayout,
    name: '权限管理',
    children: [
      {
        path: '/permissions/user/list',
        name: '用户管理',
        component: () =>
          import(
            /** webpackChunkName: "permissions" */ '@/views/permissions/user/index.vue'
          ),
      },
      {
        path: '/permissions/role/list',
        name: '角色管理',
        component: () =>
          import(
            /** webpackChunkName: "permissions" */ '@/views/permissions/role/index.vue'
          ),
      },
      {
        path: '/permissions/user/permission/:type/:id',
        meta: { hidden: true },
        component: () =>
          import(
            /** webpackChunkName: "permissions" */ '@/views/permissions/user/permission/index.vue'
          ),
      },
      {
        path: '/permissions/organization/list',
        name: '组织架构管理',
        component: () =>
          import(
            /** webpackChunkName: "permissions" */ '@/views/permissions/organization/index.vue'
          ),
      },
      {
        path: '/permissions/function/list',
        name: '功能菜单',
        component: () =>
          import(
            /** webpackChunkName: "permissions" */ '@/views/permissions/function/index.vue'
          ),
      },
    ],
  },
  {
    path: '/payment-management',
    component: emptyLayout,
    name: '支付管理',
    children: [
      {
        path: '/payment-management/platform/list',
        name: '支付平台',
        component: () =>
          import(
            /** webpackChunkName: "platform" */ '@/views/payment-management/platform/index.vue'
          ),
      },
      {
        path: '/payment-management/pay-account/index',
        name: '支付账户',
        component: () =>
          import(
            /** webpackChunkName: "pay-account" */ '@/views/payment-management/pay-account/index.vue'
          ),
      },
      {
        path: '/payment-management/pay-account/add-round',
        name: '新建轮询规则',
        component: () =>
            import(
                /** webpackChunkName: "pay-account" */ '@/views/payment-management/pay-account/addRound.vue'
                ),
      },
      {
        path: '/payment-management/pay-account/edit-round/:id',
        name: '编辑轮询规则',
        component: () =>
            import(
                /** webpackChunkName: "pay-account" */ '@/views/payment-management/pay-account/addRound.vue'
                ),
      },
      {
        path: '/payment-management/pay-scene/index',
        name: '支付场景',
        component: () =>
          import(
            /** webpackChunkName: "pay-scene" */ '@/views/payment-management/pay-scene/index.vue'
          ),
      },
    ],
  },
  {
    path: '/promotion',
    component: emptyLayout,
    name: '推广位',
    children: [
      {
        path: '/promotion/list',
        name: '推广位',
        component: () =>
          import(
            /** webpackChunkName: "platform" */ '@/views/promotion/index.vue'
          ),
      },
    ],
  },
  // 短链接生成器
  {
    path: '/short',
    component: emptyLayout,
    name: '短链生成器',
    children: [
      {
        path: '/short/index',
        name: '短链生成器',
        component: () =>
          import(/** webpackChunkName: "platform" */ '@/views/short/index.vue'),
      },
    ],
  },
];

const constantsRoute: Array<RouteConfig> = [
  {
    path: '/',
    component: Layout,
    redirect: '/home',
    meta: { hidden: true },
    name: '统一管理平台',
    children: [
      {
        path: '/home',
        name: '供应链管理平台',
        component: () =>
          import(/** webpackChunkName: "home" */ '@/views/Home.vue'),
      },
    ],
  },
  {
    path: '/login',
    component: () => import('@/views/Login.vue'),
    meta: { hidden: true },
  },
  {
    path: '*',
    component: () => import('@/views/404.vue'),
    meta: { hidden: true },
  },
];
const baseRouters = microAppRouter.concat(routes);
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: constantsRoute,
});

router.beforeEach(async (to, from, next) => {
  // 首先本地是否有token
  if (to.path !== '/login') {
    // 本地无 token
    if (!storage.get(ACCESS_TOKEN)) {
      next({ path: '/login' });
      return;
    }
    // 已登录
    // 是否加载过路由
    if (store.getters.addRouters.length || store.getters.isAddRouters) {
      versionTood.isNewVersion();
      next();
      return;
    }

    // 根据菜单构建路由
    try {
      const res = await store.dispatch('getUserInfo');
      if (res.data?.menus) {
        const allRouterList = treeToList(baseRouters);
        const routerMenus = createMenus(res.data?.menus, allRouterList);
        router.addRoutes(routerMenus);
        store.commit('setRouters', routerMenus);
        store.commit('setAddRouters', true);
        next({ path: to.fullPath, replace: true });
      }
    } catch (error) {
      storage.remove(ACCESS_TOKEN);
      next({ path: '/login' });
    }
    return;
  }
  // 直接访问 /login
  storage.remove(ACCESS_TOKEN);
  next();
});
export default router;
