import smaRouter from './sma';
import scmaRouter from './scma';
import customerRouter from './customer';
import mallRouter from './mall';
import ffRouter from './ffma';
import operateRouter from './operate';
export default [
  ...scmaRouter,
  ...smaRouter,
  ...customerRouter,
  ...mallRouter,
  ...ffRouter,
  ...operateRouter,
];
