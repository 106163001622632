import { Message } from 'element-ui';
import axios, { AxiosError } from 'axios';
import storage from 'store';
import store from '@/store';
import { ACCESS_TOKEN } from '@/constants/storage';
import { axiosLoading } from '@lowinc/billbear-usercenter-apis';
// 创建 axios 实例
const request = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  timeout: 60000, // 请求超时时间
});

// token 格式错误/过期
const TOKEN_ERROR_CODE = [401, '401'];

// 异常拦截处理器
const errorHandler = (error: AxiosError) => {
  axiosLoading({ type: 'reset' });
  if (error.response) {
    const data = error.response.data;
    // token失效
    if (TOKEN_ERROR_CODE.includes(error.response.status)) {
      store.dispatch('logout', data.message);
      return Promise.reject(error);
    }
    (Message as any).closeAll();
    // 服务器错误提示
    Message.error(data.message || 'unknown error')
    // Notification.error({
    //   title: '提示',
    //   message: data.message || 'unknown error',
    // });
  }
  return Promise.reject(error);
};

// 请求拦截器
request.interceptors.request.use(config => {
  const token = storage.get(ACCESS_TOKEN);
  axiosLoading({ type: 'add' });
  if (token) {
    config.headers['Authorization'] = 'Bearer ' + token;
  }
  return config;
}, errorHandler);

// 响应拦截器
request.interceptors.response.use(response => {
  if (response.data.code === '0') {
    axiosLoading({ type: 'sub' });
    return response.data;
  }
  return errorHandler({
    name: 'codeError',
    message: response.data.message,
    response: {
      ...response,
      status: response.data.code,
    },
    config: {},
    isAxiosError: true,
    toJSON: () => response.data,
  });
}, errorHandler);

export default request;
