import { RouteConfig } from 'vue-router';
import Layout from '@/layout/index.vue';
const basePath = `/scma/apps/supply-chain`;
const router: Array<RouteConfig> = [
  {
    path: `${basePath}/supplier-manage`,
    name: `供货商管理`,
    component: Layout,
    children: [
      {
        path: `${basePath}/supplier-manage/list`,
        name: `入驻供应商列表`,
      },
      {
        path: `${basePath}/supplier-manage/add`,
        name: `入驻供应商申请`,
        meta: { hidden: true },
      },
      {
        path: `${basePath}/supplier-manage/edit`,
        name: `入驻供应商修改`,
        meta: { hidden: true },
      },
      {
        path: `${basePath}/supplier-manage/account`,
        name: `供应商账号设置`,
        meta: { hidden: true },
      },
    ],
  },
  {
    path: `${basePath}/goods-manage`,
    name: `供货链管理`,
    component: Layout,
    children: [
      {
        path: `${basePath}/goods-manage/mall-goods`,
        name: `商城商品管理`,
      },
      {
        path: `${basePath}/goods-manage/mall-goods/detail`,
        name: `商城商品详情`,
        meta: { hidden: true },
      },
      {
        path: `${basePath}/goods-manage/classify`,
        name: `商品分类`,
      },
      {
        path: `${basePath}/goods-manage/brand`,
        name: `品牌管理`,
      },
      {
        path: `${basePath}/goods-manage/service`,
        name: `服务分类`,
      },
      {
        path: `${basePath}/goods-manage/attribute/manage`,
        name: `属性管理`,
      },
      {
        path: `${basePath}/goods-manage/attribute/list`,
        name: `属性列表`,
        meta: { hidden: true },
      },
      {
        path: `${basePath}/goods-manage/label/list`,
        name: `标签管理`,
      },
    ],
  },
  {
    path: `${basePath}/order-manager`,
    name: '订单管理',
    children: [
      {
        path: `${basePath}/order/list`,
        name: `订单列表`,
      },
      {
        path: `${basePath}/order/detail/:id`,
        name: `订单详情`,
        meta: { hidden: true },
      },
    ],
  },
];
export default router;
