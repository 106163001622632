import { RouteConfig } from 'vue-router';
import EmptyLayout from '@/layout/EmptyLayout.vue';
const basePath = `/sma/apps/supplier`;
const router: Array<RouteConfig> = [
  {
    path: `${basePath}/inventoryManagement`,
    name: `库存管理`,
    component: EmptyLayout,
    children: [
      {
        name: `库存发布`,
        path: `${basePath}/inventory/publish`,
      },
      {
        name: `新建库存`,
        path: `${basePath}/inventory/add`,
        meta: { hidden: true },
      },
      {
        name: `库存编辑`,
        path: `${basePath}/inventory/edit/:id`,
        meta: { hidden: true },
      },
      {
        name: `库存出售`,
        path: `${basePath}/inventory/list`,
      },
      {
        name: `虚拟商品明细`,
        path: `${basePath}/inventory/goods-detail/VIRTUAL/:id`,
        meta: { hidden: true },
      },
      {
        name: `实物商品明细`,
        path: `${basePath}/inventory/goods-detail/REAL/:id`,
        meta: { hidden: true },
      },
      {
        name: `库存审核`,
        path: `/scma/apps/supply-chain/inventory/check`,
      },
      {
        name: `库存详情`,
        path: `/scma/apps/supply-chain/inventory/detail`,
        meta: { hidden: true },
      },
      {
        name: `重置中心库存审核`,
        path: `/scma/apps/supply-chain/vouchercenter/examine`,
      },
      {
        name: `重置中心库存审核库存详情`,
        path: `/scma/apps/supply-chain/vouchercenter/auditDetails/:id/:state`,
        meta: { hidden: true },
      },
    ],
  },
];
export default router;
