import {
  registerMicroApps,
  start,
  addGlobalUncaughtErrorHandler,
} from 'qiankun';
import apps from './apps';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import store from '@/store';
import { ACCESS_TOKEN } from '@/constants/storage';
import storage from 'store';
import actions from './actions';
// 全局通信
addGlobalUncaughtErrorHandler(event =>
  console.log('qiankun global error', event),
);
actions.onGlobalStateChange(state => {
  // state: 变更后的状态; prevState: 变更前的状态
  if (state.type === 'logout') {
    // 状态管理器类型为登录过期，则调起store公共方法退出登录
    store.dispatch('logout', state.payload);
    return;
  }
  if (state.type === 'getToken') {
    // 状态管理器类型为获取token，则本地浏览器储存token
    actions.setGlobalState({
      type: 'token',
      payload: storage.get(ACCESS_TOKEN),
    });
  }
  if (state.type === 'fatherSetSupplier') {
    store.commit('setSupplier', state.payload)
  }
  if (state.type === 'fatherSetHotelSupplier') {
    store.commit('setHotelSupplier', state.payload)
  }
  if (state.type === 'fatherSetSaleSupplier') {
    store.commit('setSaleSupplier', state.payload)
  }
  if (state.type === 'fatherSetVouerSupplier') {
    store.commit('setVouerSupplier', state.payload)
  }
  if (state.type === 'fatherSetStockSupplier') {
    store.commit('setStockSupplier', state.payload)
  }

  if (state.type === 'setSupplierList') {
    store.commit('setSupplierList', state.payload)
  }

  console.log(state, '---主应用状态---')
}, true);
//生命周期
export default function() {
  registerMicroApps(apps, {
    beforeLoad: () => {
      NProgress.start();
      return Promise.resolve();
    },
    afterMount: () => {
      NProgress.done();
      return Promise.resolve();
    },
  });
  start({
    sandbox: false,
  });
}
