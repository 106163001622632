import { RouteConfig } from 'vue-router';
import Layout from '@/layout/index.vue';
const basePath = `/customer/apps/customer-sys`;

const router: Array<RouteConfig> = [
  {
    path: `${basePath}/customer`,
    name: '客户管理',
    component: Layout,
    children: [
      {
        name: '客户列表',
        path: `${basePath}/customer/list`,
      },
      {
        name: '商城管理',
        path: `${basePath}/customer/mall`,
        children: [
          {
            name: '商品库列表',
            path: `${basePath}/customer/goodsList`,
          },
          {
            name: '商品库编辑',
            path: `${basePath}/customer/goodsLib`,
            meta: { hidden: true },
          },
          {
            name: '商品组编辑',
            path: `${basePath}/customer/goodsGroup`,
            meta: { hidden: true },
          },
        ],
      },
    ],
  },
];
export default router;
