<template>
  <div class="sidebar">
    <el-menu
      class="side-menu"
      mode="vertical"
      :collapse="isCollapse"
      :collapse-transition="false"
      text-color="#303133"
      background-color="#fff"
      active-text-color="#303133"
      :default-active="selectedKeys"
      :unique-opened="true"
      @select="hanldeMenu"
    >
      <sidebar-item v-for="menu in routes" :key="menu.path" :item="menu" />
    </el-menu>
  </div>
</template>
<script>
import SidebarItem from './SidebarItem.vue';
import { mapState, mapGetters } from 'vuex';
import EventBus from '@/utils/EventBus';
export default {
  name: 'Sidebar',
  components: {
    SidebarItem,
  },
  data() {
    return {
      inlineCollapsed: false,
    };
  },
  computed: {
    ...mapGetters(['sidebar']),
    selectedKeys() {
      return this.$route.path;
    },
    isCollapse() {
      return !this.sidebar.opened;
    },
    ...mapState({
      routes: state => state.addRouters,
    }),
  },
  mounted() {
    // console.log(this.selectedKeys);
    this.findIndexPath()
  },
  methods: {
    hanldeMenu(index, indexPath) {
      EventBus.emit('handleSelectMenu', indexPath);
    },
    // 菜单数组反查递归
    findId(array, path, arr) {
      for (let i = 0; i < array.length; i++) {
        if (array[i].path === path) {
          arr.unshift(array[i].path); // 插入子path
          return array[i];
        } else if (Array.isArray(array[i].children)) {
          const result = this.findId(array[i].children, path, arr);
          if (result) {
            arr.unshift(array[i].path); // 递归，依次插入父级path到数组头
            return result;
          }
        }
      }
      return null;
    },
    // 多级数组反查菜单并返回
    findIdList(array, path) {
      return new Promise(resolve => {
        const arr = [];
        this.findId(array, path, arr);
        resolve(arr);
      });
    },
    // 查找indexPath
    async findIndexPath() {
      const temp = await this.findIdList(this.routes, this.selectedKeys);
      this.$nextTick(() => {
        EventBus.emit('handleSelectMenu', temp);
      });
    },
  },
};
</script>

<style lang="less" scoped>
.sidebar {
  height: calc(100% - 80px);
  overflow: auto;
  background-color: #fff;
  text-align: left;
  .side-menu {
    border-right: none;
    font-weight: bold;
  }
  &::-webkit-scrollbar {
    width: 0;
  }
  ::v-deep .el-submenu__title {
    font-weight: normal;
  }
  ::v-deep .el-menu-item {
    font-weight: normal;
  }
}
</style>
