/* eslint-disable @typescript-eslint/camelcase */
import request from '../utils/request';
import qs from 'qs';
const headers = {
  'Content-Type': 'application/x-www-form-urlencoded',
};
type LoginParams = {
  phone?: string;
  code?: string;
};
export const loginApi = {
  getCode(phone: string) {
    return request.get(
      `${window.location.origin}/api/authCenter/auth/sms/send/${phone}`,
      {
        headers,
      },
    );
  },
  login(data: LoginParams) {
    const params = {
      authType: 'mobile',
      client_id: 'lx',
      client_secret: 'lx',
      loginName: data.phone,
      password: data.code,
    };
    return request.post(
      `${window.location.origin}/api/authCenter/auth/web/login`,
      params,
    );
  },
};
