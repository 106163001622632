import { RouteConfig } from 'vue-router';
import { MENU_TYPE, MENU_VISIBLE } from '../constants/user-status';
/**
 * 过滤动态路由
 */
export function createMenus(
  menus: any[],
  routers: RouteConfig[],
): RouteConfig[] {
  return menus.map(menu => {
    if (menu?.children?.length) {
      menu.children = createMenus(menu.children, routers);
    }
    let router: any = routers.find(item => item.path === menu.href);
    //增加默认判断（默认进入/mall/apps/imall component）
    if (menu.rootNode && !router) {
      router = routers.find(item => item.path === '/mall/apps/imall');
    }
    // 目录
    if (menu.type === MENU_TYPE.DIR) {
      return {
        name: menu.name,
        path: menu.href,
        component: router?.component,
        children: menu.children,
        meta: { icon: menu.icon },
      };
    }
    // 菜单
    if (menu.type === MENU_TYPE.MENU) {
      return {
        name: menu.name,
        path: menu.href,
        component: router?.component,
        meta: { hidden: menu.visible === MENU_VISIBLE.HIDE },
      };
    }
    // 按钮
    return {
      name: menu.name,
      path: menu.url,
      meta: { hidden: true },
    };
  });
}
