<template>
  <div v-if="!isHidden(item)">
    <router-link v-if="!isHasChild(item)" :to="item.path">
      <el-menu-item :index="item.path">
        <span>{{ item.name }} </span>
      </el-menu-item>
    </router-link>
    <el-submenu v-else :index="item.path">
      <!-- <span slot="title" class="submenu-title">
        <i :class="['iconfont', item.meta.icon]" v-if="!!item.meta.icon" />
        <span>{{ item.name }} {{ item.meta.icon }}</span>
      </span> -->
      <template slot="title">
        <item
          v-if="item.meta"
          :icon="item.meta && item.meta.icon"
          :title="item.name"
        />
      </template>
      <sidebar-item
        v-for="child in item.children"
        :item="child"
        :key="child.path"
        :is-nest="true"
        class="nest-menu"
      />
    </el-submenu>
  </div>
</template>

<script>
import Item from './Item';
export default {
  components: { Item },
  name: 'SidebarItem',
  props: {
    item: {
      type: Object,
      require: true,
    },
  },
  data() {
    return {};
  },
  methods: {
    isHidden(route) {
      return route?.meta?.hidden;
    },
    isHasChild(route) {
      return Boolean(route?.children?.length);
    },
  },
};
</script>
<style scoped lang="less">
.submenu-title {
  .iconfont {
    margin-right: 5px;
    color: #0cc1f9;
  }
}
</style>
